/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { 
    margin: 0;
    background-image: url("/assets/images/bghome2.webp");
    background-repeat: repeat;
    font-family: Roboto;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf) format("opentype");
}
@font-face {
    font-family: Comfortaa;
    src: url(assets/fonts/Comfortaa-Regular.ttf) format("opentype");
}
@font-face {
    font-family: Roboto;
    src: url(assets/fonts/Roboto-Light.ttf) format("opentype");
}
@font-face {
    font-family: Pacifico;
    src: url(assets/fonts/Pacifico-Regular.ttf) format("opentype");
}

::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-track{
    background-color: white;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 50px;
}

.error {
    font-weight: lighter;
    color: red;
    text-align: center;
    padding: 15px;
}

#main {
    overflow:auto;
    padding-bottom:150px; /* this needs to be bigger than footer height*/
}

.progressSpinner {
    margin:0 auto;
}

.example-spacer {
    flex: 1 1 auto;
}

.matBackDrop {
    background-color: rgba(41, 66, 98, 0.6);
}




.perfOverlay {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.9);
    overflow-y: auto;
    z-index: 998;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    transition: opacity 1s;
}


.perfRightSideNav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgba(0, 128, 128, 0.9);
    z-index: 999;
    overflow-x: hidden;
    transition: 0.5s;
}

.perfRightSideNav .perfCloseBtn {
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: 36px;
}

.perfHeader {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.perfHeadTitle {
    margin-top: 10px;
    font-weight: 500;
    color: rgb(138, 56, 118);
}

.perfCloseBtn {
    transform: scale(2);
    position: absolute;
    top: 15px;
    left: 20px;
    color: white;
}

.perfBgDiv {
    width: 100%;
    height: 100%;
    background-color: teal;
    position: fixed;
    z-index: -1;
    opacity: 0.5;
    top: 0;
}

.perfFormHeader {
    font-weight: lighter;
    font-family: Poppins;
    color: grey;
    margin-bottom: 25px;
}

.perfMainContainer {
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: white;
}

.perfFormContainer {
    margin: 50px;
}

.step-progressbar-wrapper {
    background: #fff;
    width: 100vh;
    display: inline;
    padding-top: 10px;
    padding-bottom: 5px;
}

.step-progressbar li {
    list-style-type: none;
    width: 33.33%;
    float: left;
    padding-top: 5px;
    font-size: 14px;
    position: relative;
    text-align: center;
    color: #7d7d7d;
}

.step-progressbar li:before {
    width: 50px;
    height: 50px;
    content: "";
    line-height: 50px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #fff;
}

.step-progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 30px;
    left: -50%;
    z-index: 0;
}

.step-progressbar li:first-child:after {
    content: none;
}

.step-progressbar li.active,
.step-progressbar li.inprogress {
    color: #014d90;
}

.step-progressbar li.active:before,
.step-progressbar li.inprogress:before {
    border-color: #014d90;
    background: #014d90;
    color: white;
}

.step-progressbar li.active + li:after,
.step-progressbar li.inprogress + li:after {
    background-color: #014d90;
}

.step-progressbar li.active:before {
    font-family: 'Material Icons';
    content: "done";
    font-size: 25px;
}

.step-progressbar li.inprogress:before {
    font-family: 'Material Icons';
    content: "more_horiz";
    font-size: 25px;
}

.step-progressbar li::before {
    font-family: 'Material Icons';
    content: "blur_on";
    font-size: 25px;
    color: grey;
}

// @media screen and (max-height: 450px) {
//     .rightSideNav {
//         padding-top: 15px;
//     }

//     .rightSideNav a {
//         font-size: 18px;
//     }
// }